exports.components = {
  "component---src-controller-audit-js": () => import("./../../../src/controller/audit.js" /* webpackChunkName: "component---src-controller-audit-js" */),
  "component---src-controller-casestudy-js": () => import("./../../../src/controller/casestudy.js" /* webpackChunkName: "component---src-controller-casestudy-js" */),
  "component---src-controller-category-js": () => import("./../../../src/controller/category.js" /* webpackChunkName: "component---src-controller-category-js" */),
  "component---src-controller-consultation-js": () => import("./../../../src/controller/consultation.js" /* webpackChunkName: "component---src-controller-consultation-js" */),
  "component---src-controller-consultingservice-js": () => import("./../../../src/controller/consultingservice.js" /* webpackChunkName: "component---src-controller-consultingservice-js" */),
  "component---src-controller-implementation-js": () => import("./../../../src/controller/implementation.js" /* webpackChunkName: "component---src-controller-implementation-js" */),
  "component---src-controller-infographic-js": () => import("./../../../src/controller/infographic.js" /* webpackChunkName: "component---src-controller-infographic-js" */),
  "component---src-controller-knowledgebase-js": () => import("./../../../src/controller/knowledgebase.js" /* webpackChunkName: "component---src-controller-knowledgebase-js" */),
  "component---src-controller-newspress-js": () => import("./../../../src/controller/newspress.js" /* webpackChunkName: "component---src-controller-newspress-js" */),
  "component---src-controller-page-js": () => import("./../../../src/controller/page.js" /* webpackChunkName: "component---src-controller-page-js" */),
  "component---src-controller-pageletter-js": () => import("./../../../src/controller/pageletter.js" /* webpackChunkName: "component---src-controller-pageletter-js" */),
  "component---src-controller-post-js": () => import("./../../../src/controller/post.js" /* webpackChunkName: "component---src-controller-post-js" */),
  "component---src-controller-tutorial-js": () => import("./../../../src/controller/tutorial.js" /* webpackChunkName: "component---src-controller-tutorial-js" */),
  "component---src-controller-user-js": () => import("./../../../src/controller/user.js" /* webpackChunkName: "component---src-controller-user-js" */),
  "component---src-controller-video-js": () => import("./../../../src/controller/video.js" /* webpackChunkName: "component---src-controller-video-js" */),
  "component---src-controller-welcome-js": () => import("./../../../src/controller/welcome.js" /* webpackChunkName: "component---src-controller-welcome-js" */),
  "component---src-controller-workshop-js": () => import("./../../../src/controller/workshop.js" /* webpackChunkName: "component---src-controller-workshop-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-pl-szukaj-js": () => import("./../../../src/pages/pl/szukaj.js" /* webpackChunkName: "component---src-pages-pl-szukaj-js" */)
}

